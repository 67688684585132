body {
    font-family: "Exo 2", sans-serif;
    background-color: black;
    overflow-y: hidden;
    overflow-x: hidden;

    font-optical-sizing: auto;
    font-size: 16px; 
    color: #f7f8f9;
    line-height: 16px;

}

#info {
    position: absolute; 
    margin-top: 100px;
    width: 100%;
    height: 100%;

    float: center; 
    padding: 0px;
    text-align: center; 
    box-sizing: border-box;
    vertical-align: right; 
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    pointer-events: none;

    z-index: 0; /* TODO Solve this in HTML */

    background: rgba(0,0,0,0.25); /* Fondo semi-transparente */
    color: white; /* Color del texto */
    padding: 10px; /* Espacio entre el borde del contenedor y su contenido */
}

/* En otras circunstancias esto va del lado derecho, siento que estorba menos */ 

#instrucciones {

    /*border: 1px solid #ccc;*/ /* Borde para mayor claridad */

    display: none;
    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: black gray;   /* scroll thumb and track */
    
    position: absolute; 
    top: 34%;
    left: 10%; 
   
    width: 39%;
    height: 60%;
    
    overflow-x: auto; 
    overflow-y: auto;  /*Agrega la barra de desplazamiento vertical cuando sea necesario */

    text-align: left; 
    box-sizing: border-box;

    /*
    vertical-align: top; 
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 2; 
   */
    pointer-events: none;
    z-index: 1;

    background: rgba(0,0,0,0.9); /* Fondo semi-transparente */
    color: white; /* Color del texto */
    padding: 40px; /* Espacio entre el borde del contenedor y su contenido */
}

#mensajes {

    /*border: 1px solid #ccc;*/ /* Borde para mayor claridad */
    margin-bottom: 0px; /* Ajusta este valor según el espacio que desees entre párrafos */

    display: none;
    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: black gray;   /* scroll thumb and track */
    
    position: absolute; 
    top: 12%;
    left: 10%; 
   
    width: 80%;
    height: 20%;
    
    overflow-y: auto;  /*Agrega la barra de desplazamiento vertical cuando sea necesario */
    overflow-x: auto; 
    overflow-y: auto;  /*Agrega la barra de desplazamiento vertical cuando sea necesario */
    text-align: left; 
    box-sizing: border-box;

    /*
    vertical-align: top; 
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 2; 
   */
    pointer-events: none;
    z-index: 1;

    background: rgba(0,0,0,0.9); /* Fondo semi-transparente */
    color: gray; /* Color del texto */
    padding: 40px; /* Espacio entre el borde del contenedor y su contenido */
}

#container {
    position: absolute;
    top: 0px;
    width: 100%;
    bottom: 0px;
    z-index: 0;
}

#clickableAwesomeFont {
    cursor: pointer;
    position:absolute;
    right: 0%;
    top: 5%;
    margin-right: 10%; 
      
}

a {
  color: white;
}

@font-face {
  font-family: 'jgs';
  src: url('../static/fonts/jgs_Font.ttf');
}

.image-container {
    display: none; 
    position: absolute;
    top: 34%;
    left: 50%; 
   
    width: 40%;
    height: 30%;
    z-index: 1; 
  }
  
  /* Estilo para la imagen dentro del contenedor */
  .image-container img {
    width: 100%;
    
  }

  p {
    margin-bottom: 1px !important /* Ajusta este valor según el espacio que desees entre párrafos */
  }
